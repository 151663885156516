import User from '@/services/User'
import { Modal, notification } from 'antd'
import { history } from 'umi'
import TimeHelper from './TimeHelper'
import Storage, { STORE_KEYS } from '@/utils/Storage'
import axios from 'axios'
import ErrorUtils from './ErrorUtils'
import { Timeout } from '@/utils/utils'
const qs = require('qs')
class RequestHelper {
    DOMAIN = REACT_APP_API
    DOMAIN_UPLOAD = this.DOMAIN + '/crm/upload/files'
    token = ''

    setToken = (token: string) => (this.token = token)
    setDomain = (data: string) => {
        this.DOMAIN = data
    }
    makeSignature = (data: any) => {
        return data
    }

    stringify = (obj: any) => {
        return JSON.stringify(obj)
    }

    imgUrl = (path: string) =>
        path && path.indexOf('http') === 0 ? path : `${this.DOMAIN}/${path}`

    querify = (url: string, queryObject: any) => {
        let newUrl = url
        if (queryObject === undefined) return newUrl
        newUrl += `?${qs.stringify(queryObject)}`
        return newUrl
    }

    getAuthenHeaders = () => {
        return {
            authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
        }
    }

    handleException = async (res: any) => {
        // if (!res || !res.exception_code) throw new Error(get(res, 'message') || 'Unknown API Error');
        TimeHelper.runOnceIn(
            'API_EXCEPTION',
            1000,
        )(() => {
            switch (res.error_code) {
                case 40300:
                    Modal.confirm({
                        content: 'Phiên làm việc hết hạn, vui lòng đăng nhập lại',
                        onOk: () => {
                            // history.replace('/user/login')
                            history.push('/user/login')
                            User.logout()
                            // TODO Logout
                        },
                        okText: 'OK',
                        okButtonProps: { danger: true },
                        cancelButtonProps: { hidden: true },
                    })
                    break
                case 1003:
                    User.refreshToken()
                    break
                default:
                    break
            }
        })
        throw res
    }

    get = async ({ url = '', data = {}, headers = {} }) => {
        const apiUrl = this.querify(
            this.DOMAIN + url,
            !data || Object.keys(data).length === 0
                ? this.makeSignature({})
                : this.makeSignature(data),
        )
        const response = await fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
                ...headers,
            },
            signal: Timeout(10).signal,
        })
        const res = await response.json()
        if (!res || res.signal !== 1) {
            await this.handleException(res)
        }
        return res
    }

    post = async ({ url = '', data = {}, headers = {} }) => {
        const response = await fetch(this.DOMAIN + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
                ...headers,
            },
            body: this.stringify(this.makeSignature(data)),
            signal: Timeout(10).signal,
        })
        const res = await response.json()

        if (!res || res.signal !== 1) {
            await this.handleException(res)
        }
        return res
    }

    put = async ({ url = '', data = {}, headers = {} }) => {
        const response = await fetch(this.DOMAIN + url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
                ...headers,
            },
            body: this.stringify(this.makeSignature(data)),
            signal: Timeout(10).signal,
        })
        const res = await response.json()
        if (!res || res.signal !== 1) {
            await this.handleException(res)
        }
        return res
    }

    delete = async ({ url = '', data = {}, headers = {} }) => {
        const response = await fetch(this.DOMAIN + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
                ...headers,
            },
            body: this.stringify(this.makeSignature(data)),
            signal: Timeout(10).signal,
        })
        const res = await response.json()
        if (!res || res.signal !== 1) {
            await this.handleException(res)
        }
        return res
    }

    deleteQuery = async ({ url = '', data = {}, headers = {} }, handleException = false) => {
        const apiUrl = this.querify(
            this.DOMAIN + url,
            !data || Object.keys(data).length === 0
                ? this.makeSignature({})
                : this.makeSignature(data),
        )
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
                ...headers,
            },
        })
        const res = await response.json()
        if (!res || res.signal !== 1) {
            await this.handleException(res)
        }
        return res
    }

    uploadFile = async (url: string, data: any, config?: any, options?: Record<string, any>) => {
        try {
            if (config) {
                if (!config.headers) {
                    config.headers = {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        authorization: `Bearer ${Storage.get(`${STORE_KEYS.USER_TOKEN}`)}`,
                    }
                }
            }
            const form = new FormData()
            form.append('file', data)
            if (options && Object.keys(options).length > 0) {
                Object.keys(options).forEach(function (key) {
                    form.append(key, options[key])
                })
            }

            const response = await axios.post(url, form, config)
            return response.data
        } catch (err) {
            notification.error({ message: ErrorUtils.parseError(err.response.data) })
        }
    }
}

export default new RequestHelper()
