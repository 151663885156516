export default {
    'component.tagSelect.expand': 'Hiện',
    'component.tagSelect.collapse': 'Ẩn bớt',
    'component.tagSelect.all': 'Tất cả',

    /**
     * * Common
     */
    'common.logout': 'Đăng xuất',
    'common.submit': 'Gửi',
    'common.cancel': 'Huỷ',
    'common.pls_input': '{name} không được để trống',
    'common.completed': 'Hoàn thành',
    'common.st_went_wrong': 'Đã có lỗi xảy ra',
    'common.continue': 'Tiếp tục',
    'common.goback': 'Quay lại',
    'common.agree': 'Đồng ý',
    'common.confirm': 'Xác nhận',
    'common.show_more': 'Xem thêm',
    'common.collapse': 'Thu gọn',
    'common.processing': 'Đang thực hiện',
    'common.success': 'Thành công',
    'common.invalid_email': 'Email không hợp lệ',
    'common.invalid_phone': 'Số điện thoại không hợp lệ',
    'common.limit_text': 'Vui lòng nhập tối đa {number} kí tự',
    'common.min_text': 'Vui lòng nhập tối thiểu {min} kí tự',
    'common.success_workspace': 'Cài đặt workspace thành công!',
    'common.invalid_regex': 'Kí tự không hợp lệ',
    'common.add': 'Thêm',
    'common.edit': 'Sửa',
    'common.summary': 'Tổng',
    'common.addedit': 'Thêm/sửa',
    'common.only_char_number': 'Chỉ được bao gồm số và chữ cái',
    'common.update': 'Cập nhật',
    'common.edit_staff': 'Sửa nhanh',
    'common.save': 'Lưu',
    'common.close_retry': 'Đóng và thử lại',
    'common.total': 'Tổng số',
    'common.upload_only': 'Bạn chỉ có thể tải lên tệp JPG / JPEG / PNG!',
    'common.image_size': 'File tải lên phải nhỏ hơn {size}MB!',
    'common.delete': 'Xóa',
    'common.no_data': 'Không có dữ liệu',
    'common.is_active': 'Đang bật',
    'common.is_inactive': 'Đang tắt',
    'common.data': 'Trường dữ liệu',
    'common.invalid_number': 'Định dạng số không hợp lệ',
    'common.warning': 'Cảnh báo',
    'common.whitespace_invalid': 'Không được phép chứa khoảng trắng',
    'common.undefined': 'Không xác định',
    'common.invalid_option': 'Dữ liệu đã bị ẩn / xoá',
    'common.default': 'Mặc định',
    'common.greater_zero': 'Giá trị phải lớn hơn 0',
    'common.loading': 'Đang tải',
    'common.error_occurs': 'Có lỗi xảy ra, vui lòng thử lại',
    'common.data_error': 'Vui lòng kiểm tra lại dữ liệu',
    'common.loading_export': 'Dữ liệu đang được xuất. Vui lòng chờ trong giây lát.',
    'common.limit_min_text': 'Vui lòng nhập từ {min} đến {max} kí tự',
    'common.limit_only_min_text': 'Vui lòng nhập ít nhất {min} kí tự',
    'common.export': 'Xuất dữ liệu',
    'common.title': 'CRM',
    'common.warning.auto_delete_when_expired':
        'Lưu ý: Dữ liệu sẽ tự động xoá vĩnh viễn sau 90 ngày kể từ ngày xoá',
    'common.emptyText': 'Chưa có dữ liệu',
    'common.search': 'Tìm kiếm',
    'common.create': 'Thêm mới',
    'common.confirm_delete': 'Xác nhận xoá',
    'common.content_delete': 'Bạn có chắc muốn xoá dữ liệu này không?',
    'common.content_force_delete': 'Bạn có chắc muốn xoá vĩnh viễn dữ liệu này không?',
    'common.confirm_restore': 'Xác nhận khôi phục',
    'common.content_restore': 'Bạn có chắc muốn khôi phục dữ liệu này không?',
    'common.delete_success': 'Xoá dữ liệu thành công',
    'common.restore_success': 'Khôi phục dữ liệu thành công',
    'common.confirm_update': 'Xác nhận thay đổi',
    'common.confirm_update_content': 'Bạn có chắc muốn thay đổi dữ liệu này không?',
    'common.drag_upload_title': 'Chọn hoặc thả một tệp vào vùng này để tải lên',
    'common.drag_upload_subtitle': 'Chỉ hỗ trợ xls/xlsx, tối đa 3MB',
    'common.upload': 'Tải lên',
    'common.import_result_header': 'Nhập dữ liệu',
    'common.import_content_change_data':
        'Có {success} dòng dữ liệu thành công, {error} dòng dữ liệu thất bại',
    'common.import_download_error_file': 'Vui lòng tải file để xem chi tiết.',
    'common.close': 'Đóng',
    'common.out_of_date': '(Quá hạn {days} ngày)',
    'common.day_left': '(Còn {days} ngày)',
    'common.hour_left': '(Còn {hours} giờ)',
    'common.current_day': '(Hôm nay)',
    'common.print': 'In',
    'common.from_date': 'Từ ngày',
    'common.to_date': 'Đến ngày',
    'common.female': 'Nữ',
    'common.male': 'Nam',
    'common.customize_edit': 'Chỉnh sửa',
    'common.tag': 'Tag',
    'common.filter': 'Lọc theo',
    'common.content_status.published': 'Đã xuất bản',
    'common.content_status.all': 'Tất cả trạng thái',
    /**
     * * Login

     */
    'login.title': 'Đăng nhập Gamota CRM',
    'login.description': 'Đăng nhập nhanh qua ACheckin QRCode',
    'login.btn_login': 'Đăng nhập bằng tài khoản ',
    'login.welcome': 'Chào mừng đến với Gamota CRM',
    'login.username': 'Tài khoản: hadt',
    'login.password': 'Mật khẩu: 123456',
    'login.button': 'Đăng nhập',
    'login.username_required': 'Vui lòng nhập tài khoản',
    'login.password_required': 'Vui lòng nhập mật khẩu',
    'login.scan_qr': 'Quét mã QR bằng App ACheckin để đăng nhập nhanh',
    'login.btn_login_loading': 'Đang đăng nhập ...',

    /**
     * * Dashboard
     */
    'dashboard.detail': 'Xem chi tiết',

    /**
     * Customer
     */
    'customer.all_customer': 'Tất cả khách hàng',
    'customer.active_customer': 'Khách hàng đã kích hoạt',
    'customer.pending_customer': 'Khách hàng chưa kích hoạt',
    'customer.table.all_customer': 'Tất cả khách hàng',
    'customer.table.index': 'ID',
    'customer.table.name': 'Username',
    'customer.table.created_at': 'Ngày tạo',
    'customer.table.phone': 'Số Điện Thoại',
    'customer.table.email': 'Email',
    'customer.table.rank': 'Hạng',
    'customer.table.ticket': 'Ticket',
    'customer.table.last_login': 'Lần cuối đăng nhập',
    'customer.table.gender': 'Giới tính',
    'customer.table.status': 'Trạng thái',
    'customer.table.channel': 'Channel',
    'customer.table.mobile_noti': 'Để xem được đầy đủ thông tin',
    'customer.table.mobile_noti_sub': 'Vui lòng xem trên máy tính hoặc laptop.',
    'customer.statistic.total_customer': 'Register Users',
    'customer.statistic.member': 'Active Users',
    'customer.statistic.total_vip_member': 'Users VIP',
    'customer.statistic.current_month': '1% tháng này',
    'customer.all_members.en': 'All Members',
    'customer.status.pending': 'Chưa kích hoạt',
    'customer.status.active': 'Đã kích hoạt',
    'customer.detail.addition_info': 'THÔNG TIN BỔ SUNG',
    'customer.detail.general_info': 'Thông tin chung',
    'customer.detail.edit_info': 'Cập nhật thông tin',
    'customer.detail.title': 'Xem chi tiết',
    'customer.detail.channel': 'Đa kênh',
    'customer.detail.paid': 'Đã chi tiêu',
    'customer.detail.interaction_number': 'Lượt tương tác',
    'customer.detail.paid_order': 'Hoá đơn đã thanh toán',
    'customer.detail.avg_per_order': 'Giá trung bình mỗi lần chi tiêu',
    'customer.detail.like': 'Like',
    'customer.detail.follow': 'Follow',
    'customer.detail.share': 'Chia sẻ',
    'customer.detail.group': 'Nhóm khách hàng',
    'customer.detail.current_game': 'Game đang chơi',
    'customer.overview.birthday_greeting':
        'Hôm nay là sinh nhật của khách hàng {customer_name} ({date_of_birth}). Hãy gửi một lời chúc tới {sex} ấy!',
    'customer.game_play.name': 'Tên game',
    'customer.game_play.character_name': 'Tên nhân vật',
    'customer.game_play.created_at': 'Ngày đăng ký',
    'customer.game_play.level': 'Level',
    'customer.game_play.total_deposit_amount': 'Tổng tiền đã nạp',
    'customer.game_item.id': 'ID',
    'customer.game_item.name': 'Tên items',
    'customer.game_item.rarity': 'Độ hiếm',
    'customer.game_item.quantity': 'Số lượng',
    'customer.game_item.collect_type': 'Hình thức',
    'customer.game_item.status': 'Tình trạng',
    'customer.ticket.short_content': 'Tóm tắt nội dung',
    'customer.ticket.created_at': 'Thời gian tạo ticket',
    'customer.ticket.contact_channel': 'Kênh liên lạc',
    'customer.ticket.person_in_charge': 'CS phụ trách',
    'customer.ticket.priority': 'Ưu tiên',
    'customer.ticket.status': 'Trạng thái',
    'customer.payment.transaction_id': 'Mã giao dịch',
    'customer.payment.payment_method': 'Hình thức',
    'customer.payment.created_at': 'Thời gian',
    'customer.payment.game_name': 'Game nạp',
    'customer.payment.amount': 'Số tiền',
    'customer.payment.status': 'Trạng thái',
    'customer.profile.region': 'Quốc gia/Khu vực',
    'customer.profile.district': 'Quận Huyện',
    'customer.profile.status': 'Tình trạng dịch vụ',
    'customer.profile.language': 'Ngôn ngữ',
    'customer.profile.gamota_id': 'Gamota ID',
    'customer.profile.facebook_id': 'Facebook ID',
    'customer.profile.zalo_id': 'Zalo ID',
    'customer.profile.viber_number': 'Viber Number',
    'customer.note': 'Ghi chú',
    'customer.note.placeholder': 'Nhập nội dung phản hồi ...',
    'customer.note.save_note': 'Lưu ghi chú',

    'dashboard.analytics': 'Analytics',
    'dashboard.users_behavior': 'Users Behavior',
    'dashboard.statistic.total_users': 'Total Users',
    'dashboard.statistic.active_users': 'Active Users',
    'dashboard.statistic.dau': 'DAU',
    'dashboard.statistic.mau': 'MAU',
    'dashboard.statistic.retention_rate': 'Retention Rate',
    'dashboard.statistic.asd': 'Average Session Duration (ASD)',
    'dashboard.statistic.aspu': 'Average Sessions per User (ASPU)',
    'dashboard.statistic.total_pending': 'Total Pending',
    'dashboard.statistic.users_behavior': 'Users Behavior',
    'dashboard.statistic.communication_channel': 'Communication Channel',
    'dashboard.statistic.devices': 'Devices',
    'dashboard.statistic.geo': 'Vị trí địa lý',
    'dashboard.statistic.users': 'Users',
    'dashboard.statistic.revenue': 'Revenue',
    'dashboard.statistic.paying_user_rate': 'Paying User Rate',
    'dashboard.statistic.arpt': 'Average Revenue Per Transaction - ARPT',
    'dashboard.statistic.arppu': 'Average Revenue Per Paying User - ARPPU',
    'dashboard.statistic.top_earn_user': 'Top Earning by Users',
    'dashboard.statistic.top_earn_game': 'Top Earning Games',
    'dashboard.statistic.installs': 'Installs',
    'dashboard.statistic.cpi': 'Cost Per Install - CPI',
    'dashboard.statistic.acquisition_channels': 'Acquisition Channels',
    'dashboard.statistic.itrcr': 'Install to Registration Conversion Rate',
    'dashboard.statistic.platform': 'Platform',
    'dashboard.statistic.media_source': 'Media Source',
    'dashboard.statistic.spent': 'Spent',
    'dashboard.statistic.instsall': 'Install',
    'dashboard.statistic.nru': 'NRU',
    'dashboard.statistic.pu': 'PU',
    'dashboard.statistic.rev': 'REV',
    'dashboard.statistic.cpi_std': 'CPI',
    'dashboard.statistic.cpn': 'CPN',
    'dashboard.statistic.arpu': 'ARPU',
    'dashboard.statistic.arppu_std': 'ARPPU',
    'dashboard.statistic.nru_install': 'NRU/INSTALL %',
    'dashboard.statistic.roas_3': 'ROAS 3 %',
    'dashboard.statistic.roas_7': 'ROAS 7 %',
    'dashboard.statistic.roas_30': 'ROAS 30 %',
    'dashboard.statistic.compared': 'Compared to last 7 days',
    'dashboard.statistic.other_device': 'Khác',
    'dashboard.statistic.undefine': 'Không xác định',
    'dashboard.statistic.dau_tooltip': 'Số lượng user active trong ngày',
    'dashboard.statistic.mau_tooltip': 'Số lượng user active trong tháng',
    'dashboard.statistic.retention_tooltip': 'Tỷ lệ user quay trở lại sau thời gian nhất định',
    'dashboard.statistic.total_users_tooltip': 'Tổng số user',
    'dashboard.statistic.asd_tooltip': 'Thời gian hoạt động trung bình trên mỗi lần chơi',
    'dashboard.statistic.aspu_tooltip': 'Số lượt hoạt động trung bình trên mỗi user',
    'dashboard.statistic.revenue_tooltip':
        'Doanh thu (chỉ tính trong game, không tính landing và các hoạt động outgame khác)',
    'dashboard.statistic.pur_tooltip': 'Tỷ lệ user trả phí so với tổng số user',
    'dashboard.statistic.arpt_tooltip': 'Doanh thu trung bình trên mỗi giao dịch',
    'dashboard.statistic.arppu_tooltip': 'Doanh thu trung bình trên mỗi người chơi trả phí',
    'dashboard.statistic.install_tooltip':
        'Lượt tải, được xác định khi user đã tải xong và launch app lần đầu tiên',
    'dashboard.statistic.cpi_tooltip': 'Chi phí trên mỗi install',
    'dashboard.statistic.ac_tooltip': 'Các kênh tiếp cận',
    'dashboard.statistic.itrcr_tooltip':
        'Tỷ lệ người dùng từ khi cài đặt app đến khi hoàn thành đăng ký',

    'cms.content_manager': 'Quản lý bài viết',
    'cms.new_content': 'Soạn bài viết mới',
    'cms.table.id': 'ID',
    'cms.table.published_at': 'Ngày xuất bản',
    'cms.table.avatar': 'Hình đại diện',
    'cms.table.title': 'Tên bài viết',
    'cms.table.like': 'Lượt thích',
    'cms.table.view': 'Lượt xem',
    'cms.table.status': 'Trạng Thái',
    'cms.table.action': 'Thao tác',
    'cms.table.find_content': 'Tìm kiếm bài viết',
    'cms.table.filter_status': 'Lọc trạng thái',
    'cms.table.filter_date': 'Lọc theo thời gian',
    'cms.table.unpublish': 'Đã xuất bản {published}/{total} nội dung',

    'button.new': 'Tạo mới',
    'button.download': 'Tải xuống',
    'button.create_status': 'Tạo trạng thái',
    'link.download': 'Tải về',

    'dropdown.filter': 'Lọc theo',

    'input.search_customer': 'Tìm kiếm theo SĐT hoặc tên KH hoặc Appota ID',
    'input.search_content': 'Tìm kiếm theo nội dung',
    'input.search_transaction_id': 'Tìm giao dịch theo ID',
    'statistic.average_transaction_value': 'Giá trị trung bình đơn',
    'customer.note.update_note': 'Cập nhật ghi chú',
    'common.upload_error': 'Tải file thất bại',
    'common.show_pagination': 'Hiển thị dữ liệu từ {from} đến {to} trong tổng số {total} mục',
    'activity.transaction': 'Khách hàng {name} đã nạp tiền vào game {gameName}',
    'activity.login': 'Khách hàng {name} đã đăng nhập vào game {gameName}',
    'common.permission': 'Quyền quản trị',
    'common.role_name': 'Tên quyền quản trị',
    'common.description': 'Mô tả',
    'common.permission.label': 'Quyền',
    'common.title_permission': 'Phân quyền chức danh : {name}',
    'common.edit_permission': 'Chỉnh sửa phân quyền',
    'common.view_member_list': 'Xem danh sách thành viên',
    'common.all_permission': 'Tất cả các quyền',
    'common.delete_permission': 'Xóa quyền quản trị',
    'common.confirm_delete_permission_name': 'Bạn muốn xoá quyền quản trị ‘{name}’?',
    'permission.create.success': 'Tạo mới quyền quản trị thành công!',
    'permission.update.success': 'Chỉnh sửa quyền quản trị thành công!',
    'permission.remove_success': 'Xoá nhóm quyền thành công',
    'permission.option.can_update': 'Thêm/Sửa',
    'permission.option.can_delete': 'Xoá',
    'permission.option.can_view': 'Xem',
    'scope_name.dashboard': 'Dashboard',
    'scope_name.customer': 'Quản trị khách hàng',
    'scope_name.article': 'Quản lý bài viết',
    'permission_name.dashboard_player_metric': 'Player metrics',
    'permission_name.dashboard_monetization_metric': 'Monetization metrics',
    'permission_name.dashboard_acquisition_metric': 'Acquisition metrics',
    'permission_name.customer_profile': 'Thông tin cơ bản',
    'permission_name.customer_game': 'Game đã chơi',
    'permission_name.customer_payment': 'Nạp',
    'permission_name.customer_note': 'Ghi chú',
    'permission_name.customer_ticket': 'Ticket',
    'permission_name.article_post': 'Bài viết',
    'permission_name.article_video': 'Video',
    'permission.require': 'Bắt buộc phải chọn cài đặt 1 phân quyền',
    'common.member_list': 'Danh sách thành viên ‘{name}’',
    'common.search_member': 'Tìm kiếm thành viên ...',
    'common.add_people_to_location': 'Thêm thành viên',
    'common.remove_location': 'Xoá khỏi quyền quản trị',
    'resignation.staff': 'Nhân viên',
    'common.register_account': 'Tài khoản đăng ký',
    'common.department': 'Bộ phận',
    'common.confirm_delete_permission_member': 'Bạn muốn xóa thành viên khỏi quyền quản trị?',
    'common.confirm_delete_member_success': 'Xóa thành viên thành công',
    'permission.add_to_group_success': 'Thêm thành viên thành công',
    'common.admin_group': 'Nhóm quản trị',
    'common.delete_admin': 'Xoá thành viên',
    'common.confirm_delete_admin': 'Bạn muốn xóa thành viên khỏi quản trị viên?',
    'permission.owner': 'Owner',
    'common.stt': 'STT',
    'cms.table.create_post': 'Thêm bài viết mới',
    'article.title': 'Tiêu đề',
    'article.title.placeholder': 'Nhập tiêu đề bài viết',
    'article.description': 'Mô tả ngắn',
    'article.description.placeholder': 'Nhập mô tả bài viết',
    'article.avatar': 'Ảnh đại diện',
    'article.publish': 'Xuất bản',
    'article.platform': 'Nền tảng',
    'article.content': 'Nội dung',
    'article.link_content': 'Đường dẫn: https://...',
    'article.modal_calender': 'Đặt lịch',
    'article.auto_save': 'Lưu nháp',
    'article.preview': 'Xem trước',
    'article.update.success': 'Cập nhật bài viết thành công',
    'article.create.success': 'Tạo bài viết thành công',
    'article.cover_url_select': 'Vui lòng chọn ảnh đại diện',
    'article.delete_title': 'Xoá bài viết',
    'article.confirm_delete': 'Bạn có chắc muốn xoá bài viết này không?',
    'cms.game_manager': 'Quản lý game',
    'cms.game.find_content': 'Tìm kiếm game',
    'cms.game.create': 'Thêm game',
    'cms.game.name': 'Tên game',
    'cms.game.name.placeholder': 'Nhập tên game',
    'cms.game.total_follower': 'Số lượt flow',
    'cms.game.total_follower_placeholder': 'Nhập số lượt flow',
    'cms.game.total_download': 'Số lượt tải',
    'cms.game.total_download_placeholder': 'Nhập số lượt tải',
    'cms.game.rate': 'Số rate',
    'cms.game.rate_placeholder': 'Nhập số rate',
    'cms.game.link_android': 'Link tải Android',
    'cms.game.link_ios': 'Link tải IOS',
    'game.update.success': 'Cập nhật game thành công',
    'game.create.success': 'Tạo game thành công',
    'game.delete_title': 'Xoá game',
    'game.confirm_delete': 'Bạn có chắc muốn xoá game này không?',
    'cms.event.create': 'Thêm Event',
    'cms.event.tag': 'Nhãn',
    'event.update.success': 'Cập nhật event thành công',
    'event.create.success': 'Tạo event thành công',
    'event.delete_title': 'Xoá event',
    'event.confirm_delete': 'Bạn có chắc muốn xoá event này không?',
    'cms.table.create_video': 'Thêm video mới',
    'cms.table.find_video': 'Tìm kiếm video',
    'cms.video_manager': 'Quản lý video',
    'video.delete_title': 'Xoá video',
    'video.confirm_delete': 'Bạn có chắc muốn xoá video này không?',
    'video.title.placeholder': 'Nhập tiêu đề video',
    'video.update.success': 'Cập nhật video thành công',
    'video.create.success': 'Tạo video thành công',
    'call.delete_title': 'Xoá cuộc gọi',
    'call.confirm_delete': 'Bạn có chắc muốn xoá cuộc gọi này không?',
    'article.video_url_select': 'Vui lòng chọn video',
    'ticket.name': 'Tên phiếu',
    'ticket.game': 'Game',
    'ticket.assign': 'Gán cho',
    'ticket.type': 'Loại',
    'ticket.prioritize': 'Ưu tiên',
    'ticket.priority': 'Độ ưu tiên',
    'ticket.priority.low': 'Thấp',
    'ticket.priority.medium': 'Trung bình',
    'ticket.priority.high': 'Cao',
    'ticket.information': 'Thông tin',
    'ticket.history': 'Lịch sử ticket',
    'ticket.action': 'Lịch sử hành động',
    'ticket.appotaId': 'Appota ID',
    'ticket.amount': 'Số tiền nạp',
    'ticket.server': 'Máy chủ',
    'ticket.account': 'Nhân vật',
    'ticket.role_id': 'Role ID',
    'ticket.facebook': 'Facebook',
    'ticket.full_name': 'Họ tên',
    'ticket.character_information': 'Thông tin nhân vật',
    'ticket.personal_information': 'Thông tin cá nhân',
    'ticket.direct_payment_order_id': 'Mã Order',
    'common.update.success': 'Cập nhật thành công',
    'ticket.variant': 'Nguồn tiền',
    'ticket.error_group': 'Nhóm lỗi',
    'ticket.source': 'Source',
    'ticket.type_vip': 'Type ticket vip',
    'ticket.gpa_code': 'Mã giao dịch GPA',
    'giftcode.add': 'Thêm quà tặng',
    'giftcode.edit': 'Cập nhật quà tặng',
    'giftcode.name': 'Tên',
    'giftcode.description': 'Mô tả',
    'giftcode.type': 'Loại',
    'giftcode.game': 'Game',
    'giftcode.disposable': 'Dùng 1 lần',
    'giftcode.shared': 'Dùng chung',
    'giftcode.date': 'Hạn dùng',
    'giftcode.valid_time': 'Ngày hợp lệ',
    'giftcode.expire_time': 'Ngày hết hạn',
    'giftcode.total_giftcode': 'Số lượng',
    'giftcode.delete_title': 'Xoá quà tặng',
    'giftcode.confirm_delete': 'Bạn có chắc muốn xoá quà tặng này không?',
    'giftcode_detail.delete_title': 'Xoá code',
    'giftcode_detail.confirm_delete': 'Bạn có chắc muốn xoá code này không?',
    'giftcode.code': 'Code',
    'giftcode_detail.edit': 'Cập nhật code',
    'giftcode_detail.add': 'Thêm code',
    'checkin_setting.number_of_spins': 'Số lượt quay may mắn',
    'customer.profile.pancake_id': 'Pancake ID',
    'customer.profile.pancake_link': 'Pancake Link',
    'banner.add': 'Thêm banner',
    'banner.edit': 'Cập nhật banner',
    'banner.url': 'URL',
    'banner.image': 'Hình ảnh',
    'banner.image_required': 'Vui lòng chọn hình ảnh',
    'banner.delete_title': 'Xoá banner',
    'banner.confirm_delete': 'Bạn có chắc muốn xoá banner này không?',
    'url.validate': 'Liên kết không phải là một url hợp lệ',
    'common.min_length': 'Vui lòng nhập ít nhất {min} kí tự',
}
